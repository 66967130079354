import { useState, useEffect } from 'react';
import { default as NavBar } from "./components/NavBar"
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem('token') !== null);
  }, []);

  return (
    <div className="App" style={{ backgroundColor: 'rgb(0, 0, 0)' }}>
      <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
    </div>
  );
}

export default App;