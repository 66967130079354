import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { default as Home } from './pages/Home';
import { default as LEADERBOARDS } from './pages/Leaderboards';
import { default as Logo } from './WebsitePics/NexusLogo.png';
import { default as ReportIssueButton } from '../ReportIssueButton';
// import { default as Fileshare } from './pages/Fileshare';
import { default as DiscordServers } from './pages/DiscordServers';
import { default as CGB } from './pages/CGB'
// import { default as Update } from './pages/Update'
// import { default as About } from "./pages/About"

export default function MainNav() {
  const [expanded, setExpanded] = useState(false);

  function handleRouteChange() {
    // function to handle route change
  }

  return (
    <Router onNavigation={handleRouteChange}>
      <div className="Nav">
        <ReportIssueButton />

        <Navbar expand="lg" expanded={expanded}>
          <Container className="NavBar">
            <Navbar.Brand href="/" className="logo" onClick={() => setExpanded(false)}>
              <img src={Logo} width='160' height='40' alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} style={{ borderColor: 'white' }} />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link to="/Speedruns" className="nav-link" onClick={() => setExpanded(false)}>Speedruns</Link>
                <Link to="/CGB" className="nav-link" onClick={() => setExpanded(false)}>CGB</Link>
                {/* <Link to="/Fileshare" className="nav-link" onClick={() => setExpanded(false)}>Fileshare</Link> */}
                <Link to="/DiscordServers" className="nav-link" onClick={() => setExpanded(false)}>Discord Servers</Link>
                {/* <Link to="/About" className="nav-link" onClick={() => setExpanded(false)}>About Us</Link> */}
                {/* <Link to="/Update" className="nav-link" onClick={() => setExpanded(false)}>Shinobi Update</Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <main>
        <div>
          <Routes>
            <Route path='/Speedruns' element={<LEADERBOARDS />} />
            <Route path='/CGB' element={<CGB />} />
            {/* <Route path='/Fileshare' element={<Fileshare />} /> */}
            <Route path='/DiscordServers' element={<DiscordServers />} />
            {/* <Route path='/Update' element={<Update />} /> */}
            {/* <Route path='/About' element={<About />} /> */}
            <Route path='/' element={<Home />} />
          </Routes>
        </div>
      </main>
    </Router>
  );
}
