import React from 'react';
import './DiscordServers.css';

const DiscordServers = () => {
    const servers = [
        { id: '1040475128091385876', name: "Shinobi" },
        { id: '932741816548229120', name: "Halo Hangout" },
    ];

    // Convert server name to a suitable class name
    const classNameForServer = (name) => {
        return name.toLowerCase().replace(/\s+/g, '-');
    };

    return (
        <div>
            <h1>My Discord Servers</h1>
            <div className="discord-container">
                {servers.map((server, index) => (
                    <div key={index} className={`server-widget ${classNameForServer(server.name)}`}>
                        <h2 className="server-name">{server.name}</h2>
                        <iframe
                            title={`${server.name} Server`}
                            src={`https://discord.com/widget?id=${server.id}&theme=dark`}
                            width="350"
                            height="500"
                            allowTransparency="true"
                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
                        </iframe>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DiscordServers;
