import { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Leaderboards.css";
import { h3L, hrL } from "../api.js";
import { default as sandbox } from "../WebsitePics/sandbox.png";
import { default as foundry } from "../WebsitePics/foundry.png";
import { default as icebox } from "../WebsitePics/icebox.png";
import { default as avalanche } from "../WebsitePics/avalanche.png";
import { default as blackout } from "../WebsitePics/blackout.png";
import { default as waterfall } from "../WebsitePics/waterfall.png";
import { default as swordbase } from "../WebsitePics/swordbase.png";
import { default as breakpoint } from "../WebsitePics/breakpoint.png";
import { default as tempest } from "../WebsitePics/tempest.png";
import { default as highlands } from "../WebsitePics/highlands.png";
import { default as breakneck } from "../WebsitePics/breakneck.png";
import { default as forgeworld } from "../WebsitePics/forgeworld.png";
import { default as crowne } from "../WebsitePics/crowne.png";
import { default as bellscolumnsv2 } from "../JumpMapPics/bellscolumnsv2.PNG";
import { default as chatoyant } from "../JumpMapPics/chatoyant.PNG";
import { default as clickitty } from "../JumpMapPics/clickitty.PNG";
import { default as dvnpathfinder } from "../JumpMapPics/dvnpathfinder.PNG";
import { default as ghdholyghost } from "../JumpMapPics/ghdholyghost.PNG";
import { default as impawsible } from "../JumpMapPics/impawsible.PNG";
import { default as jgdhouseofjumps } from "../JumpMapPics/jgdhouseofjumps.PNG";
import { default as jgdchallenge } from "../JumpMapPics/jgdchallenge.PNG";
import { default as jgdcoaster } from "../JumpMapPics/jgdcoaster.PNG";
import { default as jgdnerfed } from "../JumpMapPics/jgdnerfed.PNG";
import { default as jgdtechnical } from "../JumpMapPics/jgdtechnical.PNG";
import { default as jtgauntlet } from "../JumpMapPics/jtgauntlet.PNG";
import { default as kittymew } from "../JumpMapPics/kittymew.PNG";
import { default as kittyreset } from "../JumpMapPics/kittyreset.PNG";
import { default as lfbanjo } from "../JumpMapPics/lfbanjo.PNG";
import { default as lfchinacat } from "../JumpMapPics/lfchinacat.PNG";
import { default as jhskyspire } from "../JumpMapPics/jhskyspire.PNG";
import { default as jhpowertower } from "../JumpMapPics/jhpowertower.PNG";
import { default as jnogbang } from "../JumpMapPics/jnogbang.PNG";
import { default as jnogtrials } from "../JumpMapPics/jnogtrials.PNG";
import { default as lffutz } from "../JumpMapPics/lffutz.PNG";
import { default as lfysicynips } from "../JumpMapPics/lfysicynips.PNG";
import { default as pabigfoot } from "../JumpMapPics/pabigfoot.PNG";
import { default as parubble } from "../JumpMapPics/parubble.PNG";
import { default as pamango } from "../JumpMapPics/pamango.PNG";
import { default as pawakawaka } from "../JumpMapPics/pawakawaka.PNG";
import { default as soil } from "../JumpMapPics/soil.PNG";
import { default as srsinner } from "../JumpMapPics/srsinner.PNG";
import { default as syblam } from "../JumpMapPics/syblam.PNG";
import { default as wtkgabagool } from "../JumpMapPics/wtkgabagool.PNG";
import { default as wtkgambit } from "../JumpMapPics/wtkgambit.PNG";

async function fetchData(apiUrl) {
    try {
        const response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export default function Leaderboards() {
    const [game, setGame] = useState("Halo 3");
    const [record, setRecord] = useState(null);
    const [data, setData] = useState([]);
    const [animationKey, setAnimationKey] = useState(0);
    const [animationKey1, setAnimationKey1] = useState(0);
    const [visible, setVisible] = useState(false); // Menu initially hidden
    const apiUrlHalo3 = h3L;
    const apiUrlHaloReach = hrL;
    const isMobile = window.innerWidth <= 600;
    const bounceTabRef = useRef(null);
    const touchStartXRef = useRef(null); // Use ref to persist the value

    useEffect(() => {
        const fetchDataFromApi = async () => {
            let apiUrl = game === "Halo 3" ? apiUrlHalo3 : apiUrlHaloReach;
            const apiData = await fetchData(apiUrl);

            apiData[0].maps.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });

            setData(apiData);
            setRecord(null);
        };

        fetchDataFromApi();
    }, [game, apiUrlHalo3, apiUrlHaloReach]);

    useEffect(() => {
        const handleTouchStart = (event) => {
            touchStartXRef.current = event.touches[0].clientX;
            bounceTabRef.current.dataset.touchStartX = touchStartXRef.current;
        };

        const handleTouchMove = (event) => {
            const touchStartX = touchStartXRef.current;
            const touchEndX = event.touches[0].clientX;

            if (event.target === bounceTabRef.current) {
                if (touchStartX > touchEndX) {
                    const distanceX = touchStartX - touchEndX;
                    if (distanceX > 50) {
                        setVisible(true);
                    }
                }
            } else if (visible) {
                if (touchStartX < touchEndX) {
                    const distanceX = touchEndX - touchStartX;
                    if (distanceX > 50) {
                        setVisible(false);
                    }
                }
            }
        };

        document.addEventListener("touchstart", handleTouchStart);
        document.addEventListener("touchmove", handleTouchMove);

        return () => {
            document.removeEventListener("touchstart", handleTouchStart);
            document.removeEventListener("touchmove", handleTouchMove);
        };
    }, [visible]);

    const handleGameButtonClick = (selectedGame) => {
        setAnimationKey1((prevKey) => prevKey + 1);
        setGame(selectedGame);
        setRecord(null);
        if (isMobile) {
            setVisible(false);
        }
    };

    function setNewRecord(mapRecord) {
        setAnimationKey((prevKey) => prevKey + 1);
        if (isMobile) {
            setVisible(false);
        }

        let img;
        const mapBaseName = mapRecord.map_base_name.toLowerCase();
        const imageMap = {
            foundry: foundry, icebox: icebox, bellscolumnsv2: bellscolumnsv2, chatoyant: chatoyant,
            clickitty: clickitty, dvnpathfinder: dvnpathfinder, ghdholyghost: ghdholyghost, impawsible: impawsible,
            jgdhouseofjumps: jgdhouseofjumps, jgdchallenge: jgdchallenge, jgdcoaster: jgdcoaster, jgdnerfed: jgdnerfed,
            jgdtechnical: jgdtechnical, jtgauntlet: jtgauntlet, jnogbang: jnogbang, jnogtrials: jnogtrials,
            jhskyspire: jhskyspire, kittymew: kittymew, kittyreset: kittyreset, lfbanjo: lfbanjo,
            lfchinacat: lfchinacat, jhpowertower: jhpowertower, lffutz: lffutz, lfysicynips: lfysicynips,pabigfoot: pabigfoot,
            parubble: parubble, pamango: pamango, pawakawaka: pawakawaka, soil: soil, srsinner: srsinner,
            syblam: syblam, wtkgabagool: wtkgabagool, wtkgambit: wtkgambit, avalanche: avalanche, blackout: blackout,
            waterfall: waterfall, swordbase: swordbase, breakpoint: breakpoint, tempest: tempest,
            highlands: highlands, breakneck: breakneck, forgeworld: forgeworld, sandbox: sandbox
        };
        img = imageMap[mapBaseName] || sandbox;

        let runs = mapRecord.wr_holders.sort((a, b) => {
            const timeA = a.record_time.split(":").reduce((acc, time, index) => acc + parseInt(time) * [3600, 60, 1][index], 0);
            const timeB = b.record_time.split(":").reduce((acc, time, index) => acc + parseInt(time) * [3600, 60, 1][index], 0);
            return timeA - timeB;
        });

        let newRecord = [{
            mapImg: img,
            mapName: mapRecord.name,
            baseName: mapBaseName,
            mapCreator: mapRecord.creator,
            speedruns: runs
        }];

        setRecord(newRecord);
    }

    return (
        <div>
            <div className="bounce-tab" ref={bounceTabRef}></div>
            {!isMobile ? (
                <div>
                    <div className="board-switcher">
                        <button className={`board-switch-button ${game === "Halo 3" ? "active" : ""}`} onClick={() => handleGameButtonClick("Halo 3")}>Speedrunning Rules</button>
                    </div>

                    <div className="leaderboards-server-container" key={animationKey1}>
                        {data.map((item) => (
                            <div className="leaderboards-server" key={item.id}>
                                <div className="leaderboards-record-container">
                                    {item.maps.map((mapRecord, index) => (
                                        <button className={`map-record ${record != null && record[0].mapName === mapRecord.name ? "active" : ""}`} onClick={() => setNewRecord(mapRecord)} style={{ animationDelay: `${index * 0.1}s`, visibility: "hidden" }}
                                            onAnimationStart={() => {
                                                setTimeout(() => {
                                                    const element = document.getElementById(`map-${index}`);
                                                    if (element) {
                                                        element.style.visibility = "visible";
                                                    }
                                                }, index);
                                            }}
                                            key={index}
                                            id={`map-${index}`}>
                                            <div className="record-value">{mapRecord.name}</div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div>
                    {visible && (
                        <div className="mobile-right-menu visible">
                            <div className="mobile-board-switcher">
                                <button className={`board-switch-button ${game === "Halo 3" ? "active" : ""}`} onClick={() => handleGameButtonClick("Halo 3")}>Speedrunning Rules</button>
                            </div>

                            <div className="mobile-leaderboards-container" key={animationKey1}>
                                {data.map((item) => (
                                    <div className="mobile-leaderboards" key={item.id}>
                                        <div className="mobile-leaderboards-record-container">
                                            {item.maps.map((mapRecord, index) => (
                                                <button className={`mobile-map-record ${record != null && record[0].mapName === mapRecord.name ? "active" : ""}`} onClick={() => setNewRecord(mapRecord)} style={{ animationDelay: `${index * 0.1}s`, visibility: "hidden" }}
                                                    onAnimationStart={() => {
                                                        setTimeout(() => {
                                                            const element = document.getElementById(`map-${index}`);
                                                            if (element) {
                                                                element.style.visibility = "visible";
                                                            }
                                                        }, index);
                                                    }} key={index} id={`map-${index}`}>
                                                    <div className="record-value">{mapRecord.name}</div>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {record ? (
                <div className="grid-container">
                    <div className="item item-1"></div>
                    <div className="shown-records" key={animationKey}>
                        {isMobile && (
                            <div className="map-details" key={animationKey}>
                                <div className="map-details-text">Map Name: {record[0].mapName}<br></br><br></br>Map Creator: {record[0].mapCreator}
                                    <br></br><br></br>Speedrunners: {record[0].speedruns.length}
                                </div>
                            </div>
                        )}
                        <h3 className="speedrun-title">RECORDS</h3>
                        {record[0].speedruns.map((run, index) => (
                            <>
                                <div
                                    className="item item-2"
                                    style={{ animationDelay: `${index * 0.2}s`, visibility: "hidden" }}
                                    onAnimationStart={() => {
                                        setTimeout(() => {
                                            const element = document.getElementById(`item-${index}`);
                                            if (element) {
                                                element.style.visibility = "visible";
                                            }
                                        }, index);
                                    }}
                                    key={index}
                                    id={`item-${index}`}
                                >
                                    <div className="run">
                                        <div className="speedrunner">
                                            <strong>{index + 1}. {run.wr_holder}</strong>
                                            {index === 0 && <img src={crowne} alt="Crown" className="crown-icon" style={{ marginLeft: '10px', width: '30px', height: '30px', marginTop: '-4px' }} />}
                                        </div>
                                        <div className="speedrunner">
                                            Time: <strong>{run.record_time}</strong>
                                        </div>
                                        {run.video_url && (
                                            <a className="speedrunner" href={`https://${run.video_url.replace(/^https?:\/\//, '')}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>Video</a>
                                        )}
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                    {!isMobile && (
                        <div className="item item-3">
                            <div className="map-details" key={animationKey}>
                                <img className="map-image" src={record[0].mapImg} alt="Map"></img>
                                <div className="map-details-text">Map Name: <br></br>{record[0].mapName}<br></br><br></br>Map Creator: <br></br>{record[0].mapCreator}
                                    <br></br><br></br>Current Record Holder: <br></br><strong>{record[0].speedruns[0].wr_holder}</strong><br></br></div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    {game === "Halo 3" ? (
                        <div className="info-container" key={animationKey1}>
                            {isMobile && (<h10>~To see the maps swipe left~</h10>)}
                            <h1>Halo 3 Speedrunning Rules</h1>
                            <div className="rules">
                                <h3>~General Rules~</h3>
                                <p><strong>All submitted runs must be in 1st Person, should not be ran on modified versions of the map or game unless for the purpose of resetting such as in the case of Save States. Every run must start with the map in its default state, no physical alterations should be made such as moving objects or affecting the rng of certain objects such as fusion coils or bouncing golf/soccer balls</strong></p>
                            </div>
                            <div className="rules">
                                <h3>~Game Mode Rules~</h3>
                                <p><strong>Unless the map specifies the need for a certain game type, only the modes with standard jump settings: Oddball, no alterations to speed, jump height, etc are valid. Third party assistance such as Butterfly, double jumping, or any player interaction one might see in CGB is disallowed. Weapons will not be forced unless the map requires you to have those weapons eg. grenades, rockets, and bruteshots. </strong></p>
                            </div>
                            <div className="rules">
                                <h3>~Map Specific Rules~</h3>
                                <p><strong>JGD Coaster ~ There is a big skip in the start of the map. Runs that use this skip will not be valid and your run will not be counted toward the leaderboards. If you use this skip we will let you know that the run is invalid.<br></br><br></br>JT Gauntlet ~ Runs that use getting on top of the map skips will not be valid. If you use these skips we will let you know your run is invalid.<br></br><br></br>WTK Gambit ~ There is a huge skip at the start of the map. Runs that use this skip will not be counted toward the leaderboards. If you use this skip we will let you know that the run is invalid</strong></p>
                            </div>
                            <div className="rules">
                                <h3>~HTJ Leaderboards~</h3>
                                <p><strong>We are aware that HTJ has their own speedrunning leaderboard and some of you may have submitted runs to theirs. We are keeping our leaderboard separate from theirs and will not be using the same maps they currently use. We want to keep ours separate to keep things fresh and use different maps that have no current records. If you would like to view their leaderboard you will find it below.</strong></p>
                                <a href="https://ghst.run/speedruns" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>~GHST Runs~</a>
                            </div>
                            <div className="rules">
                                <h3>~Timer~</h3>
                                <p><strong>The timer begins on the frame the player makes a movement and ends on the frame the Oddball is picked up. Use this tool to help time your runs correctly. If you are having issues let Pownin know and we will figure it out.</strong></p>
                                <a href="https://slashinfty.github.io/yt-frame-timer/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>~Timer~</a>
                            </div>
                        </div>
                    ) : (
                        <div className="info-container" key={animationKey1}>
                            <h2>HALO REACH RULES</h2>
                            <h5>Coming soon to a website near you...<br></br><br></br><br></br></h5>
                            <h20>when they give us the damn rules...</h20>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}