
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './Home.css';


function Home() {
    const [showModal, setShowModal] = useState(false);
    const [issue, setIssue] = useState('');
    // const [powninUserId, setPowninUserId] = useState('723954817940324363');
    // const [failcatUserId, setFailcatUserId] = useState('440209248358498305');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const webhookUrl = 'https://discord.com/api/webhooks/1104606911896232001/0xHfWQZcV4BWXwFseW9EhGeWuzI3EduGtidHShhO3qkwb9s1-rcJTA0H6_EqMoZdVoqH'; // Replace this with your Discord webhook URL
            const message = `New Suggestion: ${issue}`;
            const response = await fetch(webhookUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    content: message
                })
            });
            if (response.ok) {
                console.log('Issue report sent successfully.');
            } else {
                console.error('Failed to send issue report.');
            }
        } catch (error) {
            console.error('Failed to send issue report.', error);
        }
        setShowModal(false);
        setIssue('');
        // Reset user IDs
        // setPowninUserId('Pownin_User_ID_HERE');
        // setFailcatUserId('FailCat_User_ID_HERE');
    };


    return (
        <div>
            <div className="main-container">
                <div className="content-block first-block">
                    <h2>Jump Nexus</h2>
                    <p>The website is still under development. Its purpose is to be a hub for anything related to Halo 3 trick jumping. We are a very small group of developers so development and features are slow. If you have an idea for the website reach out to Pownin on discord, or use the suggestions button below.</p>
                </div>
                <div className="content-block second-block">
                    <h2>Upcoming Features</h2>
                    <p>~Fixed CGB servers page~<br/>~Redesign~</p>
                </div>
                <div className="content-block third-block">
                    <h2>Community Suggestions</h2>
                    <p>If you have ideas for the website click the suggestions button and send them to us!</p>
                </div>
            </div>
    
            <Button className="suggestions" variant="danger" onClick={() => setShowModal(true)}>
                Suggestions
            </Button>
    
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>If you have an idea let us know!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formIssue">
                            <Form.Label>Type your ideas below</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Please enter your ideas here"
                                value={issue}
                                onChange={(event) => setIssue(event.target.value)}
                            />
                        </Form.Group>
                        <Button className="suggestionsform" variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
    
}

export default Home;