import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './MyNav.css';

export default function ReportIssueButton() {
    const [showModal, setShowModal] = useState(false);
    const [username, setUsername] = useState('');
    const [issue, setIssue] = useState('');
    const [powninUserId, setPowninUserId] = useState('723954817940324363');
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const webhookUrl = 'https://discord.com/api/webhooks/1102507538609618995/LXLDT82qfyCdenC3bcMezygCLiJ0h1pAuF4gtMI7uaq-N5146OCKkX5DAeLrIRQG64em'; // Replace this with your Discord webhook URL
            const message = `<@${powninUserId}>, ${username} encountered an issue with ${issue}`;
            const response = await fetch(webhookUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    content: message
                })
            });
            if (response.ok) {
                console.log('Issue report sent successfully.');
            } else {
                console.error('Failed to send issue report.');
            }
        } catch (error) {
            console.error('Failed to send issue report.', error);
        }
        // Close the modal
        setShowModal(false);
        // Clear the form
        setUsername('');
        setIssue('');
        // Reset user IDs
        setPowninUserId('Pownin_User_ID_HERE');
    
    };

    return (
        <div className="report-Issue">
            <Button className="report-issue" variant="danger" onClick={() => setShowModal(true)}>
                Report An Issue
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Report An Issue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Page Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the page name that has an issue"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formIssue">
                            <Form.Label>Issue</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter the issue you encountered"
                                value={issue}
                                onChange={(event) => setIssue(event.target.value)}
                            />
                        </Form.Group>
                        <Button className="report" variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}